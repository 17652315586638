/* import __COLOCATED_TEMPLATE__ from './custom-action-finished.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type CustomActionFinished from 'embercom/objects/inbox/renderable/custom-action-finished';
import LinkWithText from 'embercom/helpers/link-with-text';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type Router from '@ember/routing/router-service';

interface CustomActionFinishedPart extends RenderablePart {
  renderableData: CustomActionFinished;
}

interface Args {
  part: CustomActionFinishedPart;
}

interface Signature {
  Args: Args;
}

export default class CustomActionFinishedPartComponent extends Component<Signature> {
  @service declare session: Session;
  @service declare intl: IntlService;
  @service declare router: Router;

  // @ts-ignore helper doesn't seem to be typed
  linkWithTextHelper = LinkWithText.create(this);

  get customActionLogsUrl() {
    if (this.customActionId) {
      let customActionUrl =
        window.location.origin +
        this.router.urlFor(
          'apps.app.settings.app-settings.custom-actions.custom-action',
          this.session.workspace.id,
          this.customActionId,
          { queryParams: { selectedTab: 'logs' } },
        );

      return this.linkWithTextHelper.compute_with_href(
        customActionUrl,
        this.intl.t('inbox.custom-action-logs.text'),
      );
    }
    return;
  }

  get customActionName() {
    if (this.customActionId) {
      return this.linkWithTextHelper.compute([
        'apps.app.settings.app-settings.custom-actions.custom-action',
        this.customActionId,
        this.args.part.renderableData.customActionName,
      ]);
    }
    return this.args.part.renderableData.customActionName;
  }

  get customActionId() {
    return this.args.part.renderableData.customActionId;
  }

  get customActionResult() {
    return this.args.part.renderableData.customActionResult;
  }

  get translatedResult() {
    if (this.customActionResult === 'failed') {
      return 'inbox.custom-action-failed';
    } else {
      return 'inbox.custom-action-succeeded';
    }
  }

  get renderableData() {
    return this.args.part.renderableData;
  }

  get translatedResultWithCustomBotTitle() {
    if (this.customActionResult === 'failed') {
      return 'inbox.custom-action-failed-with-custom-bot-title';
    } else {
      return 'inbox.custom-action-succeeded-with-custom-bot-title';
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::EventParts::CustomActionFinished': typeof CustomActionFinishedPartComponent;
    'inbox2/conversation-stream/event-parts/custom-action-finished': typeof CustomActionFinishedPartComponent;
  }
}
