/* import __COLOCATED_TEMPLATE__ from './phone-dialler-contact-selectors.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import { type TaskGenerator } from 'ember-concurrency';
import { restartableTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import type AdminSummary from 'embercom/objects/inbox/admin-summary';
import type SearchableDocument from 'embercom/objects/inbox/searchable-document';
import type TeamSummary from 'embercom/objects/inbox/team-summary';
import type UserSummary from 'embercom/objects/inbox/user-summary';
import type InboxSearchSuggestionsService from 'embercom/services/inbox-search-suggestions-service';
import type Inbox2AssigneeSearch from 'embercom/services/inbox2-assignee-search';
import { ChannelAvailabilityCode } from 'embercom/services/inbox2-teammate-activity-service';
import type IntercomCallService from 'embercom/services/intercom-call-service';

interface Signature {
  Element: HTMLElement;
  Args: {
    searchPlaceholder: string;
    showAdminsList: boolean;
    showTeamsList: boolean;
    showUsersList: boolean;
    isActivelyCallingId?: string | number;
    isActivelyCalling: boolean;
    actionIcon: InterfaceIconName;
    usersActionTooltipText: string;
    adminsActionTooltipText: string;
    teamsActionTooltipText: string;
    onAdminListActionClicked: (admin: AdminSummary) => void;
    onTeamListActionClicked: (team: TeamSummary) => void;
    onUserListActionClicked: (user: UserSummary) => void;
  };
}

export default class PhoneDiallerContactSelectors extends Component<Signature> {
  @service declare inboxSearchSuggestionsService: InboxSearchSuggestionsService;
  @service declare inbox2AssigneeSearch: Inbox2AssigneeSearch;
  @service declare intercomCallService: IntercomCallService;

  @tracked userSearchQuery = '';
  @tracked userInitialList: UserSummary[] = [];
  @tracked userSearchResults: UserSummary[] = [];
  @tracked isLoadingUserSearchResults = false;

  get adminsForApp(): Array<AdminSummary> {
    let allAdmins = this.inbox2AssigneeSearch.searchableAdmins;
    let adminSummaryObjects = allAdmins.map(
      (adminSearchableDocument: SearchableDocument) => adminSearchableDocument.data as AdminSummary,
    );
    adminSummaryObjects = adminSummaryObjects.filter(
      (admin) =>
        !admin.isUnassignedAssignee &&
        admin.restrictedContexts?.length === 0 &&
        admin.adminStatus?.awayModeEnabled === false &&
        (admin.adminStatus?.channelAvailability === ChannelAvailabilityCode.Both ||
          admin.adminStatus?.channelAvailability === ChannelAvailabilityCode.Phone) &&
        admin.name.toLowerCase().includes(this.userSearchQuery.toLowerCase()),
    );
    return adminSummaryObjects;
  }

  get transferTeams(): Array<TeamSummary> {
    let teamSearchableDocuments = this.inbox2AssigneeSearch.searchableTeams;
    let teamSummaryObjects = teamSearchableDocuments.map(
      (teamSearchableDocument: SearchableDocument) => teamSearchableDocument.data as TeamSummary,
    );
    teamSummaryObjects = teamSummaryObjects.filter(
      (team) =>
        !team.isUnassignedAssignee &&
        team.name.toLowerCase().includes(this.userSearchQuery.toLowerCase()),
    );

    return teamSummaryObjects.sort((a, b) => a.name.localeCompare(b.name));
  }

  get isRefreshingTransferOptions() {
    return taskFor(this.refreshTransferOptions).isRunning;
  }

  get isLoading() {
    if (this.isLoadingUserSearchResults) {
      return true;
    }

    if ((this.args.showTeamsList || this.args.showAdminsList) && this.isRefreshingTransferOptions) {
      return true;
    }

    return false;
  }

  constructor(owner: unknown, args: Signature['Args']) {
    super(owner, args);

    if (this.args.showTeamsList || this.args.showAdminsList) {
      taskFor(this.refreshTransferOptions).perform();
    }

    if (this.args.showUsersList) {
      taskFor(this.onQueryChanged).perform(this.userSearchQuery);
    }
  }

  @restartableTask *refreshTransferOptions(): TaskGenerator<void> {
    yield this.inbox2AssigneeSearch.loadAllAdminsAndTeams();
  }

  @restartableTask *onQueryChanged(query: string): TaskGenerator<void> {
    this.userSearchQuery = query;
    this.userSearchResults = [];
    if (!query && this.userInitialList.length > 0) {
      this.userSearchResults = this.userInitialList;
      this.isLoadingUserSearchResults = false;
      return;
    }
    this.isLoadingUserSearchResults = true;

    let users: UserSummary[] =
      yield this.inboxSearchSuggestionsService.getUserSuggestionsForDialler(
        this.userSearchQuery,
        10,
        'contains',
      );
    if (this.userInitialList.length === 0) {
      this.userInitialList = users;
    }
    this.userSearchResults = users;

    if (users.length === 0) {
      this.intercomCallService.registerCallEvent({
        action: 'user_search',
        object: 'dialler_modal',
        place: 'user_search',
        context: 'no_results',
      });
    } else {
      this.intercomCallService.registerCallEvent({
        action: 'user_search',
        object: 'dialler_modal',
        place: 'user_search',
        context: 'results_available',
      });
    }
    this.isLoadingUserSearchResults = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Calling::PhoneDiallerContactSelectors': typeof PhoneDiallerContactSelectors;
    'inbox2/calling/phone-dialler-contact-selectors': typeof PhoneDiallerContactSelectors;
  }
}
